import React from 'react';
import styled from 'styled-components';
import { Widget } from '@typeform/embed-react';
import { DynamicImage, LoadingPage, Seo } from 'components';
import { useQuizData } from 'utils/hooks';
import { mobile, useQuery } from 'styles/breakpoints';
import LCFooter from 'components/LCFooter';
import Header from 'components/Header';

const Success = () => {
  const data = useQuizData('success');
  const { isMobile } = useQuery();

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Container>
        <Header
          sticky={false}
          color={isMobile ? 'lightPurple1' : 'unset'}
          logoVariant={isMobile ? 'center' : 'left'}
        />
        <Wrapper>
          <Wrap>
            <TextContainer>
              <TextWrapper>
                <Image alt="check" src={data.icon} />
                <Title>{data.title}</Title>
                <SubTitle>{data.subTitle}</SubTitle>
                <Paragraph>{data.paragraph}</Paragraph>
              </TextWrapper>
            </TextContainer>
          </Wrap>
          <StyledWidget
            id="ehFJNVm0"
            hideHeaders
            hideFooter
            disableScroll={true}
            className="my-form"
          />
        </Wrapper>
        <FooterWrap>
          <LCFooter disclaimerText={data.disclaimerParagraphs} />
        </FooterWrap>
      </Container>
    </>
  );
};

export default Success;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: flex-end;
  padding-right: 32px;
  @media ${mobile} {
    width: unset;
    padding-right: unset;
  }
`;

const FooterWrap = styled.div`
  width: 100%;
`;

const LogoWrap = styled.div`
  padding: 24px 0px;
  background: rgb(245, 241, 247);
  width: 100%;
  display: flex;
  padding-left: 165px;
  @media ${mobile} {
    padding-left: unset;
    justify-content: center;
  }
`;

const Logo = styled(DynamicImage)`
  width: 151px;
  height: 24px;
  @media ${mobile} {
    height: 20px;
  }
`;

const Title = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 130%;
  letter-spacing: -0.036em;
  color: #222222;
  margin-top: 1rem;
  max-width: 447px;
  @media ${mobile} {
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.016em;
  }
`;

const SubTitle = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #222222;
  margin-top: 1rem;
  @media ${mobile} {
    text-align: center;
  }
`;

const Paragraph = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #222222;
  margin-top: 1rem;
  @media ${mobile} {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const Image = styled(DynamicImage)`
  height: 72px;
  width: 72px;
  @media ${mobile} {
    height: 48px;
    width: 48px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${mobile} {
    align-items: center;
  }
`;

const TextContainer = styled.div`
  height: calc(100vh - 176px);
  width: 503px;
  background: #f5f1f7;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${mobile} {
    height: unset;
    width: unset;
    padding: 0 16px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1440px;
  @media ${mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

const Container = styled.div`
  display: flex;
  background: linear-gradient(to right, #f5f1f7 50%, #ffffff 50%);
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media ${mobile} {
    flex-direction: column;
    background: unset;
  }
`;

const StyledWidget = styled(Widget)`
  width: 50%;
  @media ${mobile} {
    height: 532px;
    width: 100%;
  }
`;
